<template lang="pug">
  #list-deliveries
    b-button.float-right(v-if="user.role === 'admin'" v-model="allPackagesReceived" :disabled="allPackagesReceived" @click="manualRecepction") Recepción Manual
    b-form-checkbox(
      v-model="allSelected"
      style="padding-left: 2.25rem; width: fit-content; font-weight: bold;"
      :indeterminate="indeterminate"
      @change="toggleAll"

    ) {{ allSelected ? 'Deseleccionar todos' : 'Seleccionar todos' }}
    b-table(:items="allPackages" :fields="packagesFields" v-if="packages" responsive)
      template(v-slot:cell(selected)="{ item }")
        b-form-checkbox(v-model="item.selected" @change="handleSelect(item)")
      template(#cell(id)="{item}")
        div {{ item.id }}
        div(v-if="item.isCanceled") (Cancelado)
      template(#cell(receptionStatus)="{item}")
        b-form-checkbox(v-model="item.receptionStatus" @change='handleChange(item)')
      template(#cell(isAdditional)="{item}")
        div {{ item.isAdditional ? 'Si' : 'No' }}
      template(#cell(code)="{item}")
        div(style="text-wrap: nowrap") {{ item.code }}
      template(#cell(actions)="{item}")
        div(v-if="!item.isCanceled")
          //- b-btn.mb-2(@click="$refs.deliveryLabelTemplate.print(item)" block) Imprimir
          b-btn.mb-2(@click="$refs.allPackagesLabelTemplate.print([item])" block) Imprimir
          b-btn(@click="cancelPackage(item.realId)" variant="outline-danger" block) Cancelar
    .d-flex.justify-content-between
      div
        p Costo de Retiro: ${{ retirementPrice.toLocaleString('es-CL') }}
        p(v-if="volumeDiscount > 0") Descuento por volumen: ${{ volumeDiscount.toLocaleString('es-CL') }}
      b-btn(@click="$refs.allPackagesLabelTemplate.print(packagesToPrint)" :disabled="!packagesToPrint.length") Imprimir etiquetas seleccionadas
        b-badge.ml-2(style="font-size: 0.9rem;" variant="info") {{ !packagesToPrint.length ? '' : packagesToPrint.length }}
    PrintAllPackagesLabel(ref="allPackagesLabelTemplate")
</template>
<script>
import { Printd } from 'printd'
import PrintAllPackagesLabel from '../withdrawal/printPackages/PrintAllPackagesLabel.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ListDeliveries',
  components: {
    PrintAllPackagesLabel
  },
  props: {
    retirementPrice: { type: Number },
    volumeDiscount: { type: Number },
    packages: {
      type: Array,
      required: true
    },
    storeName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      allPackages: [],
      allPackagesReceived: false,
      packagesToPrint: [],
      allSelected: false,
      indeterminate: false
    }
  },
  created () {
    this.handleSelectedPackages()
    this.checkPackagesToReceive()
  },
  methods: {
    handleSelectedPackages () {
      const allPackages = this.packagesItems
      this.allPackages = allPackages
      const selected = this.packagesItems.filter((p) => p.selected)
      if (selected) {
        this.packagesToPrint = selected
        return selected
      } else {
        return selected
      }
    },
    handleSelect (item) {
      if (item.selected) {
        this.packagesToPrint.push(item)
      } else {
        this.allSelected = false
        this.indeterminate = true
        this.packagesToPrint = this.packagesToPrint.filter((p) => p.id !== item.id)
      }
    },
    toggleAll (checked) {
      if (checked) {
        this.allPackages = this.allPackages.map((p) => { return { ...p, selected: true } })
        this.packagesToPrint = this.allPackages
      } else {
        this.allPackages = this.allPackages.map((p) => { return { ...p, selected: false } })
        this.packagesToPrint = []
      }
      this.checkPackagesToReceive()
    },
    print () {
      console.log('print')
      const d = new Printd()
      d.print(document.getElementById('list-deliveries'), [])
    },
    async cancelPackage (id) {
      const ok = await this.$bvModal.msgBoxConfirm('¿Está seguro que desea cancelar el paquete?', {
        title: 'Cancelar paquete',
        okVariant: 'outline-danger',
        cancelVariant: 'outline-primary',
        okTitle: 'Aceptar',
        cancelTitle: 'Cancelar'
      })
      if (!ok) return
      try {
        await this.$store.dispatch('cancelPackage', { id })
        this.$root.$bvToast.toast('Paquete cancelado', {
          title: 'Éxito',
          variant: 'success',
          solid: true
        })
        this.$emit('update')
      } catch (error) {
        console.error(error)
        this.$root.$bvToast.toast('No se pudo cancelar el paquete', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      }
    },
    manualRecepction () {
      const packages = this.allPackages.filter(p => p.receptionStatus === false)
      packages.map(p => {
        const ok = this.updateReceptionPackage(p.realId, { receptionStatus: 'received' })
        if (ok) p.receptionStatus = true
      })
      this.allPackagesReceived = true
    },
    handleChange (myPackage) {
      const packageToReceive = this.allPackages.filter(p => p.receptionStatus === false)
      if (packageToReceive.length) {
        this.allPackagesReceived = false
      }
      const id = myPackage.realId
      const data = myPackage.receptionStatus ? { receptionStatus: 'received' } : { receptionStatus: 'not_received' }
      this.updateReceptionPackage(id, data)
      this.checkPackagesToReceive()
    },
    async updateReceptionPackage (id, data) {
      console.log('actualizando paquete....', id, data)
      try {
        const ok = await this.$store.dispatch('updateReceptionPackage', { id, data })
        console.log(ok)
      } catch (error) {
        console.error(error)
      }
    },
    checkPackagesToReceive () {
      const packagesToReceive = this.allPackages.filter(p => p.receptionStatus === false)
      this.allPackagesReceived = !packagesToReceive.length
    }
  },
  computed: {
    ...mapGetters(['user']),
    packagesFields () {
      let packagesFields = [
        { key: 'selected', label: 'Imprimir' },
        { key: 'code', label: 'Código' },
        { key: 'customerName', label: 'Nombre del destinatario' },
        { key: 'customerPhone', label: 'Teléfono de destinatario' },
        { key: 'customerAddress', label: 'Dirección de destino' },
        { key: 'customerCommune', label: 'Comuna de destino' },
        { key: 'observations', label: 'Observaciones' },
        { key: 'customerEmail', label: 'Correo' },
        { key: 'size', label: 'Tamaño del paquete' },
        { key: 'shippingCost', label: 'Costo de envío' },
        { key: 'receptionStatus', label: 'Recepcionado' },
        { key: 'isAdditional', label: 'Adicional' },
        { key: 'actions', label: 'Acciones' }
      ]

      if (this.user.role !== 'admin') {
        packagesFields = [{ key: 'id', label: 'N°' }].concat(packagesFields)
        packagesFields = packagesFields.filter(f => f.key !== 'receptionStatus' & f.key !== 'isAdditional')
      }

      return packagesFields
    },
    packagesItems () {
      return this.packages.map(p => {
        return {
          isCanceled: p.isCanceled,
          _rowVariant: p.isCanceled ? 'danger' : '',
          realId: p.id,
          id: p.id.toString().padStart(3, 0),
          customerName: p.customerName,
          customerPhone: p.customerPhone,
          customerAddress: p.customerAddress,
          customerCommune: p.commune.name,
          customerEmail: p.customerEmail,
          size: p.size === 'small' ? 'PEQUEÑO' : 'MEDIANO',
          shippingCost: `$${p.shippingCost.toLocaleString('es-CL')}`,
          observations: p.observations,
          storeName: this.storeName,
          customerFullAddress: `${p.customerAddress}, ${p.commune.name}`,
          receptionStatus: p.receptionStatus === 'received' || false,
          isAdditional: p.isAdditional,
          code: p.code,
          qrCode: p.qrCode,
          selected: p.selected !== undefined ? p.selected : true
        }
      })
    }
  },
  watch: {
    packagesItems: {
      handler (value) {
        const packagesToReceive = this.allPackages.filter(p => p.receptionStatus === 'not_received')
        this.allPackagesReceived = !packagesToReceive.length
      },
      deep: true
    },
    packagesToPrint (newValue, oldValue) {
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.packagesItems.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    }
  }
}
</script>
