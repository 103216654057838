<template lang="pug">
  div
    b-row.mb-2(align-h="between")
      b-col.mb-2
        h3.d-inline Regiones
      b-col(md="auto")
        b-button(v-b-modal.create-region-form-modal) Ingresar Nuevo
    b-table(:fields="fields" :items="regionData" responsive)
      template(#cell(actions)="{value}")
        .material-icons-outlined.btn-icon.mr-2.mb-1(@click="$router.push('/regiones/' + value.id)") edit
        .material-icons-outlined.btn-icon.mr-2.mb-1.boton-eliminar(@click="onDeleteRegion(value)") delete_outline
    b-modal#create-region-form-modal(ref="createRegionFormModal" hide-footer title="Creando Región" size="lg")
      region-form(@done="onDoneRegionModal")
</template>

<script>
import RegionForm from './RegionForm'
import { mapActions, mapGetters } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  components: {
    RegionForm
  },
  data () {
    return {
      fields: [
        { label: 'Nombre', key: 'name' },
        { label: 'Disponible para retiro', key: 'availableForPickup' },
        { label: 'Precio retiro urbano', key: 'urbanRetirementPrice' },
        { label: 'Precio retiro rural', key: 'ruralRetirementPrice' },
        { label: 'Precio envío pequeño', key: 'smallShippingPrice' },
        { label: 'Precio envío mediano', key: 'mediumShippingPrice' },
        { label: 'Acciones', key: 'actions' }
      ]
    }
  },
  methods: {
    ...mapActions(['listRegion', 'deleteRegion']),
    async onDoneRegionModal () {
      await this.listRegion()
      this.$refs.createRegionFormModal.hide()
      this.$emit('done')
    },
    async onDeleteRegion (region) {
      const ok = await this.$bvModal.msgBoxConfirm(`¿Está seguro que desea eliminar ${region.name}?`)
      if (!ok) return

      const response = await this.deleteRegion({ id: region.id })
      if (response.status === 200) {
        this.makeSuccessToast('La región se eliminó correctamente')
        this.$emit('done')
      } else if (response.status >= 400 && response.status < 500) {
        this.makeDangerToast(response.data.message || 'Error al eliminar la región')
      }
      await this.listRegion()
    }
  },
  computed: {
    ...mapGetters(['regions']),
    regionData () {
      return this.regions.reduce((acum, region) => acum.concat({
        name: region.name,
        availableForPickup: region.availableForPickup ? 'Si' : 'No',
        urbanRetirementPrice: region.urbanRetirementPrice,
        ruralRetirementPrice: region.ruralRetirementPrice,
        smallShippingPrice: region.smallShippingPrice,
        mediumShippingPrice: region.mediumShippingPrice,
        actions: region
      }), [])
    }
  },
  async created () {
    await this.listRegion()
  }
}
</script>
