<template lang="pug">
  #app
    navbar(v-if="user")
    router-view.content
    Footer.footer.mt-3(v-if="user")
</template>

<script>
import Navbar from './components/UI/Navbar'
import Footer from './components/UI/Footer'
import { mapGetters } from 'vuex'

export default {
  components: {
    Navbar,
    Footer
  },
  methods: {
    checkAuth () {
      const { isPublic, roles } = this.$route.meta
      const name = this.$route.name
      if (isPublic) {
        if (this.user && name === 'Login') {
          this.$router.push({ name: 'StoreDeliveryView' }) // Sólo por ahora TODO: Cambiar por futura home
        }
        if (this.user && name === 'Login' && this.user.role === 'driver') {
          this.$router.push({ name: 'DeliverysView' })
        }
      } else if (!this.user) {
        this.$router.push({ name: 'Login' })
      } else if (roles && !roles.includes(this.user.role)) {
        this.$router.push({ name: 'StoreDeliveryView' }) // Sólo por ahora TODO: Cambiar por futura home
      }
    },
    restoreUserFromLocalStorage () {
      const storedUser = JSON.parse(window.localStorage.getItem('user'))
      if (storedUser && storedUser.user.email) {
        this.$store.commit('SET_USER', { user: storedUser })
      }
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  watch: {
    async user () {
      this.checkAuth()
    },
    $route () {
      this.checkAuth()
    }
  },
  mounted () {
    this.checkAuth()
  },
  created () {
    this.restoreUserFromLocalStorage()
  }
}
</script>
<style lang="scss" scoped>
#app {
  display: flex;
  flex-direction: column;
  .content{
    flex: 1 0 auto;
  }
  .footer {
    flex-shrink: 0;
  }
}
</style>
