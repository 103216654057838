<template lang="pug">
  .recover-password-view
    .recover-password-view-container
      .h-100.row.align-items-center
        RecoverPasswordForm.col-sm-12.col-md-4
</template>
<script>
import RecoverPasswordForm from '../components/auth/RecoverPasswordForm'

export default {
  components: {
    RecoverPasswordForm
  }
}
</script>
<style lang="scss">
@import '../style/colors.scss';
.recover-password-view{
  height: 100%;
  &-container {
    height: 100%;
  }
}
.recover-password {
  margin: auto;
}
</style>
