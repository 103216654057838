<template lang="pug">
#NavBar
  .navbar-bg
  b-container(fluid="lg")
    .navbar-header
      div
        picture
          // :TODO Se comenta logo avion, en logo normal estará en responsive
          //source(srcset="../../assets/logos/avion_naranjo.png" media="(max-width: 768px)")
          img.logo(src="../../assets/logos/blanco_avion.png")
      .user-info
        .user-info-item(v-if="user.store && !isMobile")
          div.user-icon.material-icons-outlined.mr-2 store
          div {{user.store.name}}
        .user-info-item
          span.user-icon.material-icons-outlined.mr-2(v-if="!isMobile") account_circle
          span(style="font-weight: 600;" v-if="!isMobile") {{fullName}}
          b-dropdown(right no-caret variant="link" toggle-class="text-decoration-none text-white")
            template(#button-content)
              span.material-icons-outlined menu
            b-dropdown-item(@click="logout") Cerrar Sesión
    b-navbar.bar.sombra(variant="light" toggleable="md" v-if="user.role.includes('admin') || user.role.includes('store')")
      b-navbar-toggle(target="nav-collapse")
      b-collapse#nav-collapse.bg-white(is-nav)
        b-navbar-nav
          .link(v-if="['admin', 'store'].includes(user.role)")
            .material-icons-outlined local_shipping
            b-nav-item(:to="'/envios-tienda'") Envíos
          .link(v-if="['admin', 'store'].includes(user.role)")
            .material-icons-outlined event_note
            b-nav-item(:to="'/retiros-tienda'") Retiros
          .link(v-if="user.role==='admin'")
            .material-icons-outlined admin_panel_settings
            b-nav-item(:to="'/usuarios'") Adm.Usuarios
          .link(v-if="user.role==='admin'")
            .material-icons-outlined store
            b-nav-item(:to="'/tiendas'") Tiendas
          .link(v-if="user.role==='admin'")
            .material-icons-outlined near_me
            b-nav-item(:to="'/regiones'") Adm.Regiones
          .link(v-if="user.role==='store'")
            .material-icons-outlined manage_accounts
            b-nav-item(:to="'/mi-tienda'") Mi Tienda
          .link(v-if="user.role==='admin'")
            .material-icons-outlined email
            b-nav-item(:to="'/mensajes'") Mensajes
          .link(v-if="user.role==='admin'")
            .material-icons-outlined groups
            b-nav-item(:to="'/grupos'") Grupos
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      fullName: {},
      isMobile: false
    }
  },
  created () {
    this.getUser()
    this.checkMobile()
    window.addEventListener('resize', this.checkMobile)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.checkMobile)
  },
  methods: {
    ...mapActions(['logout']),
    getUser () {
      const user = JSON.parse(localStorage.getItem('user')).user
      this.fullName = `${user.name} ${user.lastName}`
    },
    checkMobile () {
      this.isMobile = window.innerWidth <= 768
    },
    checkRole (roles) {
      return roles.includes(this.user.role)
    }
  },
  computed: {
    ...mapGetters(['user'])
  }
}
</script>

<style lang="scss" scoped>
#NavBar {
  position: relative;
  .navbar-bg {
    position: absolute;
    height: calc(100% - 0px);
    width: 100%;
    background-color: var(--secondary);
  }
  .navbar-header {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    position: relative;
    min-height: 100px;
    padding: 5px 0;
    column-gap: 10px;
  }
  .user-info {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    column-gap: 10px;
    flex-grow: 4;
    .user-info-item {
      display: flex;
      font-weight: 600;
      align-items: center;
      justify-content: flex-end;
      .dropdown {
        .btn {
          padding: 0 !important;
        }
      }
    }
    .user-icon {
      display: block !important;
      color: #FCA721;
      background-color: white;
      border-radius: 50%;
      border: 2px white solid;
    }
    color: white !important;
  }
}
#NavBar .navbar-header {
  @media (max-width: 768px) {
    min-height: 70px;
  }
}
.bar {
  height: 70px;
  border-radius: 10px;
  font-family:'Source Sans Pro Semi Bold', sans-serif;
  font-weight: 700;
  font-size: 15px;
  z-index: 2;
}
.logo {
  position: relative;
  max-height: 60px;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    max-height: 35px;
  }
}

.link{
  color: var(--light-primary);
  align-items: center;
  display: flex;
  margin-right: 20px;
}
</style>
