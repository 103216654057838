import axios from '../../plugins/axios'

export const cancelPackage = (_, { id } = {}) => {
  return axios.put(`/package/${id}/cancel`)
    .then(response => response.data)
}

export const updateReceptionPackage = (_, { id, code, data } = {}) => {
  if (code) { id = code }
  return axios.put(`/package/${id}/reception`, data)
    .then(response => response.data)
    .catch(err => err.response)
}

export const listPackage = async ({ commit }, { code } = {}) => {
  try {
    const response = await axios.get(`/package/${code}`)
    commit('SET_PACKAGE', response.data)
    return response
  } catch (err) {
    return err.response
  }
}
