<template lang="pug">
  div
    #package-label-template.d-none
      div
        .content
          .delivery-label__body
            div {{ context.storeName }}
            div {{ context.customerName }}
            div {{ context.customerFullAddress }}
            div {{ context.customerPhone }}
            div {{ context.observations }}
          .qr-code
            img(v-if="context.qrCode" :src="context.qrCode" alt="código-qr")
            img(v-if="!context.qrCode" src='@/assets/qr-etiquetas.png' alt="código-qr")
            .delivery-code
              div {{ context.code }}
        .footer
          div www.12horasenvios.cl
          div +569 5159 3606
</template>

<script>

export default {
  props: {
    context: {
      type: Object,
      default: () => ({
        storeName: '-',
        customerName: '-',
        customerPhone: '-',
        customerFullAddress: '-',
        observations: '-',
        code: '-',
        qrCode: '-'
      })
    }
  }
}
</script>
