<template lang="pug">
  div(v-if="this.user.role === 'driver'")
    // Buscador de entregas
    div(class="bg-tab")
      b-input-group.pt-2(
        class="mb-3"
      )
        b-input-group-prepend.ml-2
          b-input-group-text(class="icon-search-input")
            .material-icons.icon(class="icon-search") search
        b-input#search-input-delivery(
          placeholder="Buscar Aqui..."
          v-model="search"
          @input="debouncedSearch"
        )
      b-tabs(active-nav-item-class="text-uppercase tab-deliverys" content-class="mt-3" justified)
        b-tab(:title="`Por Entregar (N°${numberDelivery})`" :active="tabSelected === 'delivery'" @click="searchDataDelivery({ params: 'delivery' })")
        b-tab(:title="`Entregados (N°${numberDelivered})`" :active="tabSelected === 'delivered'" @click="searchDataDelivery({ params: 'delivered' })")
    b-container(fluid="lg")
      p(v-show="deliveries.length !== 0 " class="title-tab") {{ saveTabSelected === 'delivered' ? 'Envíos Entregados' : 'Lista de Viajes' }}
      p(v-if="deliveries.length === 0 && !isLoading" class="text-center") {{ this.search ? 'No se encontraron resultados' : 'No hay entregas' }}
      b-card(
        v-for="delivery in deliveries"
        :key="delivery.drivInCode"
        bg-variant="light"
        class="mt-3 shadow card-envios position-relative"
      )
        b-badge(
          :style="getBadgeStyle(delivery)"
          class="custom-badge"
        ) {{ saveTabSelected === 'delivered' ? 'Entregado' : delivery.type }}
        .material-icons.icon(class="icon-play" @click="detailtDelivery(delivery)") play_circle_filled
        p.text-title {{ `#${delivery.numberStore} ${delivery.nameStore}` }}
        p.destinatario-text {{ delivery.finalCustomer }}
        p.text-address {{ delivery.address }}
        p.text-commune {{ delivery.commune }}
        p.text-observation.small-text Observaciones:  {{ delivery.observations ? delivery.observations : '-' }}
        p.text-tracking.small-text N° Seguimiento: {{ delivery.drivInCode }}
        div(v-if="isLoading" class="skeleton-container")
          b-skeleton(v-for="n in 3" :key="n" class="mt-2" type="input" animation="fade")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { io } from 'socket.io-client'
import settings from '../../../settings.js'

export default {
  name: 'TabDelivery',
  data () {
    return {
      deliveries: [],
      allDeliveriesCopy: [],
      isLoading: false,
      user: null,
      search: '',
      debounceTimer: null,
      tabSelected: '',
      numberDelivery: 0,
      numberDelivered: 0,
      websocketUrl: settings.baseApi.split('api')[0]
    }
  },
  computed: {
    ...mapGetters(['saveTabSelected'])
  },
  methods: {
    ...mapActions(['getMyDeliveries']),
    getBadgeStyle (delivery) {
      if (delivery.entregado) {
        return {
          backgroundColor: '#0C6089',
          color: 'white'
        }
      }
      return {
        backgroundColor: delivery.type === 'Retiro' ? '#A5DC93' : '#4D9A97',
        color: delivery.type === 'Retiro' ? '#4F4F4F' : 'white'
      }
    },
    debouncedSearch () {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.searchDeliveries()
      }, 500)
    },
    searchDeliveries () {
      if (this.search.trim() === '') {
        this.deliveries = [...this.allDeliveriesCopy]
        return
      }
      this.deliveries = this.allDeliveriesCopy.filter((delivery) => {
        const searchTerm = this.search.toLowerCase()
        return (
          (delivery.drivInCode && delivery.drivInCode.toLowerCase().includes(searchTerm)) ||
          (delivery.address && delivery.address.toLowerCase().includes(searchTerm)) ||
          (delivery.finalCustomer && delivery.finalCustomer.toLowerCase().includes(searchTerm)) ||
          (delivery.number && delivery.number.toLowerCase().includes(searchTerm)) ||
          (delivery.contactPhone && delivery.contactPhone.toLowerCase().includes(searchTerm))
        )
      })
    },
    async fetchDeliveries (params) {
      if (this.isLoading) return
      this.isLoading = true
      try {
        const response = await this.getMyDeliveries()
        if (response.status === 200) {
          const ENTREGADO = ['partial', 'approved', 'rejected'] // Parcial, Aprobado, Rechazado
          const POR_ENTREGAR = ['in-transit', 'assigned', 'deliver'] // En transito, Asignado, por entregar
          const data = params.params === 'delivery' ? response.data.filter(delivery => POR_ENTREGAR.includes(delivery.status)) : response.data.filter(delivery => ENTREGADO.includes(delivery.status))
          this.allDeliveriesCopy = data
          this.deliveries = [...this.allDeliveriesCopy]
          this.numberDelivery = response.data.filter(delivery => POR_ENTREGAR.includes(delivery.status)).length
          this.numberDelivered = response.data.filter(delivery => ENTREGADO.includes(delivery.status)).length
        }
      } catch (error) {
        console.error('Error al buscar', error)
      } finally {
        this.isLoading = false
      }
    },
    detailtDelivery (delivery) {
      this.$store.commit('SET_DELIVERY_SELECTED', delivery)
      this.$router.push(`/mis-envios/${delivery.id}`)
    },
    searchDataDelivery (params) {
      this.$store.commit('SET_TAB_SELECTED', params.params)
      this.deliveries = []
      this.search = ''
      this.fetchDeliveries(params)
    },
    async initWebsockets () {
      console.log('conectando a websocket')
      this.socket = io(this.websocketUrl)

      this.socket.on('delivery:updated', (data) => {
        console.log('Envío actualizado', data || '')
        if (this.user.role === 'driver') {
          if (data.update.drivers?.includes(this.user.id)) {
            this.fetchDeliveries({ params: this.saveTabSelected || 'delivery' })
          }
        }
      })
    }
  },
  created () {
    const user = JSON.parse(localStorage.getItem('user')).user
    this.user = user
    this.tabSelected = this.saveTabSelected
    this.searchDataDelivery({ params: this.saveTabSelected ? this.saveTabSelected : 'delivery' })
    this.initWebsockets()
  },
  destroyed () {
    if (!this.socket) return
    this.socket.disconnect()
    console.log('conexión a websocket terminada')
  }
}
</script>
<style lang="scss" scoped>
#search-input-delivery::placeholder {
  color: #C3C3C3;
}
#search-input-delivery {
  border: none ;
}
.icon-search {
  color: #C3C3C3;
}
.icon-search-input{
  border: none;
  background-color: transparent;
}
.title-tab{
  color: #4F4F4F;
  font-weight: 700;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;

}
.bg-tab {
  background-color: #FFFFFF;
}

</style>
