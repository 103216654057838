export default `
#package-label-template {
  border: 1px solid black;
  height: 70mm;
  width: 100mm;
  border-radius: 20px;
  padding: 4mm 3mm;
  font-size: 4.5mm;
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
}
.delivery-label__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 20px 20px 20px 0;
  padding: 0.3cm;
  width: 44mm;
}
.qr-code {
  display: flex;
  flex-direction: column;
}
.delivery-code {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 600;
  padding-top: 0.2cm;
}
.footer {
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 0 0 20px 20px;
  margin-top: 5mm;
  padding: 0.3cm 0.4cm;
}
.content {
  display: flex;
  gap: 3mm;
  justify-content: space-between;
}
img {
  width: 46mm;
  height: 46mm;
}
body {
  margin: 0;
}
@media print {
  @page {
    size: 100mm 73.6mm;
    margin: 0;
  }
}`
