<template lang="pug">
  div
    b-row.mb-2(align-h="between")
      b-col.mb-2
        h3.d-inline Usuarios
      b-col(md="auto")
        b-button(v-b-modal.create-user-form-modal) Ingresar Nuevo
    b-table(:fields="fields" :items="userData" responsive)
      template(#cell(actions)="{value}")
        .material-icons-outlined.btn-icon.mr-2.mb-1(@click="openEditModal(value)") edit
        .material-icons-outlined.btn-icon.mr-2.mb-1.boton-eliminar(@click="onDeleteUser(value)") delete_outline
    b-modal#create-user-form-modal(ref="createUserFormModal" hide-footer title="Creando Usuario")
      user-form(v-if="storeId" @done="onDoneUserModal" :defaultValues="{ role: 'store', storeId }")
      user-form(v-else @done="onDoneUserModal" :defaultValues="{ role: 'admin' }")
    b-modal(ref="updateUserFormModal" hide-footer title="Editando Usuario")
      user-form(v-if="selectedUser" @done="onDoneUserModal" :user="selectedUser" :editMode="true" :defaultValues="selectedUser.storeId ? { role: 'store' } : { role: 'admin' }")
</template>

<script>
import UserForm from './UserForm'
import { mapActions, mapGetters } from 'vuex'
import roles from '../../common/roles'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  props: {
    userList: Array,
    storeId: Number
  },
  components: {
    UserForm
  },
  data () {
    return {
      selectedUser: null,
      fields: [
        { label: 'Nombre', key: 'name' },
        { label: 'Email', key: 'email' },
        { label: 'Rol', key: 'role' },
        { label: 'Activo', key: 'active' },
        { label: 'Acciones', key: 'actions' }
      ],
      users: []
    }
  },
  methods: {
    ...mapActions(['listUser', 'deleteUser']),
    onDoneUserModal () {
      this.fetchData()
      this.$refs.createUserFormModal.hide()
      this.$refs.updateUserFormModal.hide()
      this.$emit('done')
    },
    async onDeleteUser (user) {
      const ok = await this.$bvModal.msgBoxConfirm(`¿Está seguro que desea eliminar al usuario ${user.name} ${user.lastName}?`)
      if (!ok) return

      const response = await this.deleteUser({ id: user.id })
      if (response.status === 200) {
        this.makeSuccessToast('El usuario se eliminó correctamente')
        this.$emit('done')
      } else if (response.status >= 400 && response.status < 500) {
        this.makeDangerToast(response.data.message || 'Error al eliminar usuario')
      }
      await this.fetchData()
    },
    openEditModal (user) {
      this.selectedUser = user
      this.$refs.updateUserFormModal.show()
    },
    async fetchData () {
      if (!this.storeId) {
        // Listar los Usuario en el componente Usuario
        const response = await this.listUser()
        if (response.status === 200) {
          this.users = response.data.filter(user => user.role === 'admin' || user.role === 'driver')
        } else {
          this.makeDangerToast(response.data.message || 'Error al cargar usuarios')
        }
      } else {
        // Listar los Usuario en el componente Tienda
        const response = await this.listUser({ params: { storeId: this.$route.params.storeId } })
        if (response.status === 200) {
          this.users = response.data
        } else {
          this.makeDangerToast(response.data.message || 'Error al cargar usuarios')
        }
      }
    }
  },
  computed: {
    ...mapGetters(['internalUsers']),
    userData () {
      const users = this.users
      return users.reduce((acum, user) => acum.concat({
        name: `${user.name} ${user.lastName}`,
        role: roles.find(role => role.value === user.role).text,
        email: user.email,
        active: user.active ? 'Si' : 'No',
        actions: user
      }), [])
    }
  },
  async created () {
    await this.fetchData()
  }
}
</script>
